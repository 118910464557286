<template>
  <CFooter :fixed="false">
    <div>
      Potenciado digitalmente por 
     <a href="https://clica.agency/" target="_blank"> <span class="ml-1">&copy; {{new Date().getFullYear()}} Clica | Agencia de Marketing y Comunicación.</span></a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
