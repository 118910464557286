<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    colorScheme="light"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="logo-container" to="/">
      <img  src="/assets/img/logo.svg" width="200" class="c-sidebar-brand-full"/>
      <img  src="assets/img/isologo.svg" width="40" class="c-sidebar-brand-minimized"/>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>

    <div v-if="!minimize" class="sidebar-shape">
      <img src="/assets/img/sidebar-shape.svg" alt="">
    </div>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'Sidebar',
  nav,
  computed: {
    show () {
      return this.$store.getters.getSidebarShow 
    },
    minimize () {
      return this.$store.getters.getSidebarMinimize 
    }
  },
}
</script>
