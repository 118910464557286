<template>
  <CHeader fixed with-subheader light>
    <CHeaderBrand class="ml-3 d-lg-none" style="cursor:pointer;">
      <CIcon @click.native="$store.commit('toggleSidebarMobile')" name="cil-hamburger-menu" height="30" alt="menu"/>
    </CHeaderBrand>
    <CHeaderBrand  class="ml-3 mr-auto d-lg-none"> 
      <img class="mr-1" src="/assets/img/logo.svg" width="120" alt="CalificApp">
    </CHeaderBrand>
   
    <CHeaderNav class="d-md-down-none mr-auto">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <HeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import HeaderDropdownAccnt from './HeaderDropdownAccnt'

export default {
  name: 'Header',
  components: {
    HeaderDropdownAccnt
  }
}
</script>
