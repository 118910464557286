<template>
  <div class="c-app">
    <Sidebar/>
    <CWrapper>
      <Header/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <Footer/>
    </CWrapper>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppLayout',
  components: {
    Sidebar,
    Header,
    Footer
  },
  created(){
    this.validateIfConfigLoaded()
  },
  methods: {
    ...mapActions(['loadConfig']),
    validateIfConfigLoaded(){
      if(!this.getConfig){
        this.loadConfig();
      }
    }
  },
  computed: {
    ...mapGetters({
      config: 'getConfig',
    }),
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
