<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0 pb-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CImg width="50" class="mr-2" shape="rounded" :src="store.logo" />
        <span class="d-none d-md-inline-block">{{store.name}}</span> <CIcon width="10"  name="cil-caret-bottom" class="ml-2" />
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="goToTiendaNube">
      <a class="linked-dropdown" :href="store.url + '/admin'">
      <CImg width="25" class="mr-2" shape="rounded-circle" src="/assets/img/tiendanube-icon.png" />
       Volver a TiendaNube
       </a>
    </CDropdownItem>
    <CDropdownItem @click="logoutStore" >
      <CIcon  name="cil-account-logout" class="mr-3" /> Desconectar tienda
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'

export default {
  name: 'HeaderDropdownAccnt',
  data () {
    return { 
      
    }
  },
  created(){
   },
  methods: {
    goToTiendaNube(){
      window.location.href = this.store.url + '/admin';
    },
    logoutStore(){
      Swal.fire({
        title: 'Desconectar tienda',
        html: "¿Estás seguro? <br><br> Vas a cerrar la sesión de la tienda.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2EAE84',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, desconectar!'
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteStore').then(() => this.$router.push('/tiendanube'));
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      store: 'getStore'
    })
  },
}
</script>

<style scoped>

.linked-dropdown{
  color: #111 !important;
}
.dropdown-item{
  color: #111 !important;
}
  
</style>