export default [
  {
    _name: 'CSidebarNav',
    _children: [
      /*
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },*/
      {
        _name: 'CSidebarNavItem',
        name: 'Calificaciones',
        to: '/calificaciones',
        icon: 'cil-star',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Pedidos',
        to: '/pedidos',
        icon: 'cil-cart',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Productos',
        to: '/productos',
        icon: 'cil-library',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Configuración',
        to: '/configuracion',
        icon: 'cil-settings',
        items: [
          {
          name: 'Ajustes generales',
          to: '/configuracion/general',
          icon: 'cil-options',
          },
          /*
          {
          name: 'Correos electrónicos',
          to: '/configuracion/correos',
          icon: 'cil-envelope-closed',
          },*/
        ]
      },
      /*{
        _name: 'CSidebarNavTitle',
        _children: ['Theme']
      }*/
    ]
  }
]